import * as React from 'react';
import { useState } from 'react';
import { graphql } from 'gatsby';
import { Box, Container, Stack } from '@chakra-ui/react';
import { StaticImage } from 'gatsby-plugin-image';
import SEO from '../components/seo';
import Layout from '../components/layout';
import '../styles/careers.scss';
import CareerItem from '../components/careers/career-item';

const Careers = (props) => {
  const { data } = props;
  const [careersList] = useState(data.allWpCareer.edges);

  return (
    <Layout>
      <SEO
        title="About the Ostara Company Pioneering Sustainable Nutrient Solutions | Ostara"
        description="Learn how Ostara is pioneering sustainable phosphate management with innovative technology and enhanced-efficiency fertilizers for data-driven growers."
        image="https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara_logo.png"
        keywords="Ostara, Company"
      />
      <main className="careers">
        <Box w="100%" p={4} className="product-hero-container">
          <Container maxW={1400}>
            <Box
              display="flex"
              flexDirection={['column', 'column', 'column', 'row']}
              justifyContent="space-between"
              paddingTop={[0, 0, 10, 50]}
            >
              <Box
                marginTop={[5, 5, 5, 0]}
                w={['100%', '100%', '100%', '46.5%']}
              >
                <Stack marginTop={[0, 0, 20, 20]} maxW="50rem" spacing={6}>
                  <h1>Careers</h1>
                  <p style={{ maxWidth: 550 }}>
                    Join the team at Ostara to help build a sustainable future.
                  </p>
                </Stack>
              </Box>
              <Stack
                spacing={[4, 6, 4, 6, 8]}
                w={['100%', '100%', '100%', '46.5%']}
              >
                <Box className="benefits-hero-img-container">
                  <StaticImage
                    className="hero-img"
                    placeholder="transparent"
                    src="../images/careers-hero-img.png"
                    alt="Ostara Careers hero img"
                  />
                </Box>
              </Stack>
            </Box>
          </Container>
        </Box>

        {/* Careers List */}
        <Container
          maxW={1123}
          paddingX={8}
          paddingBottom={12}
          className="careers-content__container"
        >
          <h3 className="careers-content__title">
            Interested in joining the team at Ostara?
          </h3>
          <p className="careers-content__desc">
            Send your cover letter and resume to&nbsp;
            <a href="mailto:careers@ostara.com">careers@ostara.com</a>
          </p>
          <Box className="careers-list" marginTop={55}>
            {careersList.map(({ node }) => (
              <CareerItem
                title={`${node.title} – ${node.careers.subtitle}`}
                excerpt={node.excerpt}
                slug={node.slug}
                key={node.slug}
              />
            ))}
          </Box>
        </Container>
      </main>
    </Layout>
  );
};

export default Careers;

export const pageQuery = graphql`
  query {
    allWpCareer {
      edges {
        node {
          id
          title
          slug
          excerpt
          careers {
            subtitle
          }
        }
      }
    }
  }
`;
