import * as React from 'react';
import { Link } from 'gatsby';
import { Box, Flex, Text } from '@chakra-ui/react';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { useState } from 'react';

const CareerItem = ({ title, excerpt, slug }) => {
  const [active, setActive] = useState(false);

  return (
    <Box>
      <Flex
        onClick={() => setActive(!active)}
        className="careers-item"
        justify="space-between"
        align="center"
      >
        <Text
          paddingRight="1em"
          className="careers-item__text font-medium"
          color="#044606"
        >
          {title}
        </Text>
        {active ? (
          <MinusIcon w={8} h={8} color="#044606" />
        ) : (
          <AddIcon w={8} h={8} color="#044606" />
        )}
      </Flex>
      <Box className={`careers-item__excerpt ${active ? 'active' : ''}`}>
        <Text className="careers-item__text font-medium" color="#044606">
          {excerpt.replace(/(<([^>]+)>)/gi, '')}
        </Text>
        <Box marginTop={30} paddingBottom={30}>
          <Text className="careers-item__text" color="#044606" fontWeight={700}>
            <Link className="careers-item__link" to={`/careers/${slug}`}>
              Read more <span>⟶</span>
            </Link>
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default CareerItem;
